import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useMediaQuery } from 'react-responsive';
import React,{ useState, useEffect }  from 'react'
import JSAlert from 'js-alert';
import { CFormSelect,CCard,CCardImage,CCardBody,CCardTitle,CCardText,CFormCheck,CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import $ from "jquery";
import { TfiFaceSmile } from "react-icons/tfi";
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import { GiSelfLove,GiSevenPointedStar } from "react-icons/gi";
import { FaMapMarkerAlt,FaWhatsapp,FaChevronCircleRight,FaAutoprefixer } from "react-icons/fa";
import { FcAbout,FcProcess } from "react-icons/fc";
import { VscPreview } from "react-icons/vsc";
import { MdContactSupport,MdAddIcCall } from "react-icons/md";
import axios from 'axios';
import Navbar from './Navbar';
import Header from './header';
function Home() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
  
  useEffect(() => {
    
      setIsLoaded(true);
  }, []);
  
  useEffect(() => {//on load
      if (isLoaded) {
      $(".apt_bx").val('');
      $(".lbls").eq(1).text('Mandatory');
      var dt = new Date()
      var hh=dt.getHours();
      var mm=dt.getMinutes();
      var ss=dt.getSeconds();
      var ampm = hh >= 12 ? 'PM' : 'AM';
      $("#zv4").val(ampm);
      $("#zv3").empty();
      $("#zv3").append('<option>Select</option>');
      if(ampm=="AM" || hh==12){
      $("#zv3").append('<option>10:00</option>');
      $("#zv3").append('<option>10:30</option>');
      $("#zv3").append('<option>11:00</option>');
      $("#zv3").append('<option>11:30</option>');
      $("#zv3").append('<option>12:00</option>');
      $("#zv3").append('<option>12:30</option>');  
    }
    
      if(ampm=="PM" && hh>12){
        $("#zv3").append('<option>05:00</option>');
        $("#zv3").append('<option>05:30</option>');
        $("#zv3").append('<option>06:00</option>');
        $("#zv3").append('<option>06:30</option>');
        $("#zv3").append('<option>07:00</option>');
        $("#zv3").append('<option>07:30</option>');
      }
    }
    }, [isLoaded]);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 999px)'
  })
 
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 998.9px) and (min-width:600px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 599px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  
  const sldr={
    width:'400px',
    height:'400px',
   }
   const pntr={
       color:'red',
       fontSize:'1em'
   }
   const ghj={
    backgroundImage:'url(https://localhost/dental/maadurgadental/images/tooth.png)',
    backgroundPosition:'center',
    width:'100%',
    height:'300px',
    boxShadow:'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'
   }
   const dgg={
     fontWeight:'600'
   }
   const hji={
    backgroundImage:'linear-gradient(180deg, #FFFFFF,#FFFFFF)',
     padding:'1%',
     boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
   }
   const hjit={
       fontSize:'0.80em',
    backgroundImage:'linear-gradient(180deg, #FFFFFF,#FFFFFF)',
     padding:'1%',
     boxShadow:'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
   }
   const hum={
    boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em',
   textAlign:'left'
   }
   const ado={
    fontSize:'1.3em', 
   } 
   const mkm={
  color:'black',
  fontWeigt:'600',
  fontSize:'1.1em',
   }
   const mkm1={
   color:'black',
   fontSize:'1.6em',
   }
   const mkmb={
    color:'black',
    fontWeigt:'600',
    fontSize:'0.90em',
     }
     const mkm1b={
     color:'black',
     fontSize:'0.95em',
     }
   const mkm1z={
    color:'black',
    fontSize:'1.3em',
    }
   const yys={
    backgroundImage:'linear-gradient(180deg, #FFFFFF,#FFFFFF)',
    color:'black',
    border:'none',
    fontSize:'98%',

   }
   const yyss={
    backgroundImage:'linear-gradient(180deg, #FFFFFF,#FFFFFF)',
    color:'black',
    border:'none',
    fontSize:'88%',

   }
   const yysst={
    backgroundImage:'linear-gradient(180deg, #FFFFFF,#FFFFFF)',
    color:'black',
    border:'none',
    fontSize:'78%',

   }
   const yys1={
     border:'none',
     borderBottom:'0.5px outset black',
     fontSize:'100%'
   }
   const yys1x={
     padding:'1%',
    border:'none',
    borderBottom:'0.5px outset black',
    fontSize:'96%'
  }
   const rtgv={
     boxShadow:'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px'
   }
  
   const rgp={
    width:'99%',
    cursor:'pointer',
    color:'black',
    padding:'1%',
    fontWeight:'600',
    boxShadow:'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px'
  }
  const apt_lbl={
    color:'red',
    visibility:'hidden',
    display:'none',
    fontSize:'58%',
    float:'left',
    left:'4%',
    top:'-10%',
    position:'relative'
  }
  const apt_lbl1={
    color:'red',
    visibility:'hidden',
    display:'none',
    fontSize:'58%',
    float:'left',
    left:'4%',
    top:'-10%',
    position:'relative'
  }
  const apt_lbl2={
    color:'red',
    visibility:'hidden',
    display:'none',
    fontSize:'58%',
    float:'left',
    left:'4%',
    top:'-10%',
    position:'relative'
  }
  const apt_lbl3={
    color:'red',
    visibility:'hidden',
    display:'none',
    fontSize:'58%',
    float:'left',
    left:'4%',
    top:'-10%',
    position:'relative'
  }
  const cl_lg={
   // backgroundImage:'linear-gradient(90deg, #FFFFFF,#FFFFFF)',
    borderRadius:'1%',
    width:'auto',
    float:'left',
    zIndex:'3999 !important',
    position:'fixed',
    bottom:'20.4%',
    left:'94%',
    height:'auto',
    color:'black',
    fontWeight:'700',
    cursor:'pointer'
  }
  const cl_lg1={
    // backgroundImage:'linear-gradient(90deg, #FFFFFF,#FFFFFF)',
     borderRadius:'1%',
     width:'auto',
     float:'left',
     zIndex:'3999 !important',
     position:'fixed',
     bottom:'20.4%',
     left:'90%',
     height:'auto',
     color:'black',
     fontWeight:'700',
     cursor:'pointer'
   }
   
   const header_c1={
    textAlign:'right',
    color:'black',
    
  }
  const ftr={
    backgroundImage:'linear-gradient(180deg, #FAF4B7,#F6ECBF,#FAF4B7)',
  }
  function bk_ovr(e){
    e.preventDefault();
    document.getElementById("bk_btn").style.backgroundColor="blue";
    document.getElementById("bk_btn").style.color="white";
    return false;
  }
  function clr(){
    $("#tc").prop('checked',false);
    $(".apt_bx").val('');
    $(".lbls").eq(1).text('Mandatory');
    var dt = new Date()
    var hh=dt.getHours();
    var mm=dt.getMinutes();
    var ss=dt.getSeconds();
    var ampm = hh >= 12 ? 'PM' : 'AM';
    $("#zv4").val(ampm);
    $("#zv3").empty();
    $("#zv3").append('<option>Select</option>');
    if(ampm=="AM" || hh==12){
    $("#zv3").append('<option>10:00</option>');
    $("#zv3").append('<option>10:30</option>');
    $("#zv3").append('<option>11:00</option>');
    $("#zv3").append('<option>11:30</option>');
    $("#zv3").append('<option>12:00</option>');
    $("#zv3").append('<option>12:30</option>');  
  }
  
    if(ampm=="PM" && hh>12){
      $("#zv3").append('<option>05:00</option>');
      $("#zv3").append('<option>05:30</option>');
      $("#zv3").append('<option>06:00</option>');
      $("#zv3").append('<option>06:30</option>');
      $("#zv3").append('<option>07:00</option>');
      $("#zv3").append('<option>07:30</option>');
    }
    
  }
  function bk_out(e){
    e.preventDefault();
    document.getElementById("bk_btn").style.backgroundColor="white";
    document.getElementById("bk_btn").style.color="black";
    return false;
  }
  function chk_apt(e){
    e.preventDefault();
    var fname=document.getElementsByClassName("apt_bx")[0].value;
    if(fname==""){
      document.getElementsByClassName("lbls")[0].style.visibility='visible';
      document.getElementsByClassName("lbls")[0].style.display='inline';
    }
    else
    {
      document.getElementsByClassName("lbls")[0].style.visibility='hidden';
      document.getElementsByClassName("lbls")[0].style.display='none';
    }
    return false;
  }
  function chk_apt1(e){
    e.preventDefault();
    var fname=document.getElementsByClassName("apt_bx")[1].value;
    if(fname==""){
      document.getElementsByClassName("lbls")[1].style.visibility='visible';
      document.getElementsByClassName("lbls")[1].style.display='inline';
    }
    else
    {
     // alert(fname.length);
      if(fname.length==10){
        document.getElementsByClassName("lbls")[1].style.visibility='hidden';
        document.getElementsByClassName("lbls")[1].style.display='none';
        }
        else
        {
          document.getElementsByClassName("lbls")[1].innerHTML="Invalid";
          document.getElementsByClassName("lbls")[1].style.visibility='visible';
          document.getElementsByClassName("lbls")[1].style.display='inline';
        }
    
    }
    return false;
  }
  function chk_apt2(e){
    e.preventDefault();
    var fname=document.getElementsByClassName("apt_bx")[2].value;
    if(fname==""){
      document.getElementsByClassName("lbls")[2].style.visibility='visible';
      document.getElementsByClassName("lbls")[2].style.display='inline';
    }
    else
    {
     
      document.getElementsByClassName("lbls")[2].style.visibility='hidden';
      document.getElementsByClassName("lbls")[2].style.display='none';
      
     
    }
    return false;
  }
 
  function bk_apt(e){
    e.preventDefault();
    var bn=0;
    var fname=document.getElementsByClassName("apt_bx")[bn++].value;
    var mobile=document.getElementsByClassName("apt_bx")[bn++].value;
    var date_x=document.getElementsByClassName("apt_bx")[bn++].value;
    var time_x= $("#zv3").val();
   
    var crdntl_status=0;
    var msng=new Array();
    var dx=0;
    if(fname===""){
      msng[dx++]="Please enter Full Name"+ '<br />';
      crdntl_status=1;
    }
    
    if(mobile===""){
      msng[dx++]="Please enter Mobile Number"+ '<br />';
      crdntl_status=1;
    }
    if(date_x===""){
      msng[dx++]="Please select appointment Date"+ '<br />';
      crdntl_status=1;
    }
   if(time_x=="Select"){
    msng[dx++]="Please select appointment Time"+ '<br />';
    crdntl_status=1;
   }
    if($("#tc").prop('checked')==false){
      msng[dx++]="Please agree to terms and condition"+ '<br />';
      crdntl_status=1;
    }
   
    if(crdntl_status===1){
      JSAlert.alert(msng);
    }
    else
    {
      //create appointment
      const fd1 = new FormData();
      var dtt=0;
      fd1.append('y1',document.getElementsByClassName("apt_bx")[dtt++].value);//name
      fd1.append('y2',document.getElementsByClassName("apt_bx")[dtt++].value);//mobile
      fd1.append('y3',document.getElementsByClassName("apt_bx")[dtt++].value);//date
      fd1.append('y4',document.getElementById("zv3").value);//time
      fd1.append('y5',document.getElementById("zv4").value);//am_or_pm
      axios.post('https://localhost/dental/maadurgadental/php/book_appointment.php',fd1 
).then(res=>
{
  JSAlert.alert("Booking has been scheduled!");
  clr();
});
    }
    return false;
  }
  function hr_mng(e){
    e.preventDefault();
    var mdf=$("#zv3").val();
    if(mdf=="12:00" || mdf=="12:30"){
      $("#zv4").val("PM");
    }
    else
    {
      $("#zv4").val("AM");
    }
    return false;
  }
  function hr_mng2(e){
    e.preventDefault();
    $("#zv3").empty();
      $("#zv3").append('<option>Select</option>');
    var mdf=$("#zv4").val();
    if(mdf=="PM"){
      $("#zv3").append('<option>05:00</option>');
      $("#zv3").append('<option>05:30</option>');
      $("#zv3").append('<option>06:00</option>');
      $("#zv3").append('<option>06:30</option>');
      $("#zv3").append('<option>07:00</option>');
      $("#zv3").append('<option>07:30</option>');
    }
    if(mdf=="AM"){
      $("#zv3").append('<option>10:00</option>');
      $("#zv3").append('<option>10:30</option>');
      $("#zv3").append('<option>11:00</option>');
      $("#zv3").append('<option>11:30</option>');
      $("#zv3").append('<option>12:00</option>');
      $("#zv3").append('<option>12:30</option>');  
    }
    return false;
  }
  return (
    <div className="home">
     {isDesktopOrLaptop && (
      <>
      <Header/>
  <Navbar/>
 <CContainer fluid style={{backgroundImage:'linear-gradient(180deg, #FFFFFF,#FFFFFF)',padding:'1%', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
       <CRow>
       <CCol lg={4} xl={4} xxl={4}>
        <CContainer fluid style={hji}>
        <CRow>
        <CCol lg={12} xl={12} xxl={12}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3562.4919236100573!2d80.93785900000002!3d26.760585000000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDQ1JzM4LjEiTiA4MMKwNTYnMTYuMyJF!5e0!3m2!1sen!2sin!4v1716192646046!5m2!1sen!2sin" width="100%" height="386"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </CCol>
       </CRow>
        </CContainer>
       </CCol>
         <CCol lg={4} xl={4} xxl={4}>
         <CCarousel  controls indicators dark>
         <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga1.jpeg"} alt="slide 1" style={sldr} />
  </CCarouselItem>
 
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga3.jpeg"} alt="slide 3" style={sldr} />
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga4.jpeg"} alt="slide 4" style={sldr} />
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga5.jpeg"} alt="slide 5" style={sldr} />
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga6.jpeg"} alt="slide 6" style={sldr} />
  </CCarouselItem>
  </CCarousel>
         </CCol>
         <CCol lg={4} xl={4} xxl={4}>
         <CContainer style={hji}>
      <CRow>
        <CCol lg={12} xl={12} xxl={12}>
        <CBadge style={mkm1}>Appointment</CBadge>
        </CCol>
          </CRow>
          <br/>
          <CContainer>
           <CRow>
             <CCol  lg={12} xl={12} xxl={12}>
             <CInputGroup size="lg">
                    
  <CInputGroupText style={yys}>Name</CInputGroupText>
  <CFormInput onBlur={chk_apt} className="apt_bx" style={yys1} placeholder="Enter Name" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
 
</CInputGroup>
<span className="lbls" style={apt_lbl}>Mandatory</span>
             </CCol>
      
             <CCol  lg={12} xl={12} xxl={12}>
             <CInputGroup size="lg">
  <CInputGroupText style={yys}>Mobile</CInputGroupText>
  <CFormInput onBlur={chk_apt1} className="apt_bx" style={yys1} placeholder="Enter Mobile No." aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
</CInputGroup>
<span className="lbls" style={apt_lbl1}>Mandatory</span>
             </CCol>
             <CCol  lg={12} xl={12} xxl={12}>
             <CInputGroup size="lg">
  <CInputGroupText style={yys}>Date</CInputGroupText>
  <CFormInput onBlur={chk_apt2} className="apt_bx" style={yys1} placeholder="Enter Mobile No." aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" type="date"/>
</CInputGroup>
<span className="lbls" style={apt_lbl2}>Mandatory</span>
             </CCol>
             <CCol  lg={12} xl={12} xxl={12}>
             <CInputGroup size="lg">
  <CInputGroupText style={yys}>Time</CInputGroupText>
  <CFormSelect onChange={hr_mng}  style={yys1x} id="zv3" aria-label="Default select example">
  <option>Select Time</option>
  </CFormSelect>&nbsp;&nbsp;&nbsp;
  <CFormSelect onChange={hr_mng2}  style={yys1x} id="zv4" aria-label="Default select example">
  <option>AM</option>
  <option>PM</option>
  </CFormSelect>
</CInputGroup>
<span className="lbls" style={apt_lbl3}>Mandatory</span>
<CBadge style={yyss}>Mon - Sat (Morning 10:00 AM - 01:00 PM) <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Evening 05:00 PM - 08:00 PM)</CBadge>
<CBadge style={yyss}>Mon - Sun (Morning 10:00 AM - 01:00 PM)</CBadge>
             </CCol>
             
           
             
             <CCol  lg={12} xl={12} xxl={12} style={{textAlign:'left',paddingTop:'2%'}}>
             <CFormCheck id="tc" inline style={rtgv} value="" label="I agree to terms & condition!!"/>
             <CButton color="link">Read T&C</CButton>
             </CCol>
             <br/>
             <CCol  lg={12} xl={12} xxl={12}>
             <CButton id="bk_btn" style={rgp} onClick={bk_apt} onMouseOver={bk_ovr} onMouseOut={bk_out}>Book Appointment</CButton>
             </CCol>
           </CRow>
          </CContainer>
      </CContainer>
         </CCol>
       </CRow>
      </CContainer>
      <CContainer fluid style={hji}>
        <CRow>
          <CCol lg={12} xl={12} xxl={12} style={{textAlign:'center'}}>
          <CBadge style={mkm1}><FcAbout/>About</CBadge>
          </CCol>
         
          <CCol lg={12} xl={12} xxl={12} style={{textAlign:'left',fontSize:'1.4em',fontWeight:'600'}}>
         Greetings from Maa Durga Dental Clinic,<br/>
         <p style={{textAlign:'center'}}>  <CImage fluid  src={"https://localhost/dental/maadurgadental/images/Maa_durga_dental_clinic.jpg"} alt="slide 4"  style={sldr} /> </p><br/>
         <p><span style={pntr}><GiSevenPointedStar/></span>I am dedicated to ensure that your thirty two pearls keep shining bright and healthy.</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Your smile and healthy nutrition start right from the point of chewing your food efficiently.</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Patient satisfaction and regular feedbacks  are the constant inputs which i rely on to provide the best of services.</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Our dental clinic is equiped with state of art equipment</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Schedule/Re-Schedule appointments</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Cost effective dental health care</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Hassle free Time bound services</p> 
         <p><span style={pntr}><GiSevenPointedStar/></span>Post treatment offline & online consulting</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Your smile is source of inspiration.</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Premier dental clinic in Lucknow,Uttar Pradesh</p>
          </CCol>
         
        </CRow>
      </CContainer>
      
      <CContainer fluid style={hji}>
      <CRow>
        <CCol lg={12} xl={12} xxl={12} style={{textAlign:'center'}}>
        <CBadge style={mkm1}><FcProcess/>Procedure</CBadge>
        </CCol>
          </CRow>
          <CRow>
            <CRow>
            <CCol lg={6} xl={6} xxl={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>RCT</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Treat infection at the center of a tooth
   <br/>
   <FaChevronCircleRight/> Preserve the tooth without pain and infection
   <br/>
   <FaChevronCircleRight/> Comfortable chewing and maintaining normal biting force and sensation.
    <br/>
     <FaChevronCircleRight/> Protection of other teeth from excessive wear or strain
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol lg={6} xl={6} xxl={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Scaling</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Clear plaque and tartar (also known as calculus) that has accumulated on the teeth
   <br/>
   <FaChevronCircleRight/> To help maintain healthy gums and teeth
   <br/>
   <FaChevronCircleRight/> Prevent gum diseases such as gingivitis and periodontitis.
    <br/>
     <FaChevronCircleRight/> Safe and non-invasive procedure with minimal risks.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
              <CRow>
            <CCol lg={6} xl={6} xxl={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Dental Implants</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Popular and effective solution for replacing missing teeth
   <br/>
   <FaChevronCircleRight/> Regains the ability to eat virtually anything and can smile with confidence.
   <br/>
   <FaChevronCircleRight/> Improved Speech and comfort.
    <br/>
     <FaChevronCircleRight/>  Implants are very convenient and durable thus will last many years,
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol lg={6} xl={6} xxl={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top"  style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Crown & Bridge</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Restorative treatments used to repair the appearance and function of your teeth
   <br/>
   <FaChevronCircleRight/> Help maintain dental functionality and improve the overall aesthetics of your smile
   <br/>
   <FaChevronCircleRight/> Bridges span the space where the teeth are missing.
    <br/>
     <FaChevronCircleRight/> Porcelain or ceramic crowns can be matched to the color of your natural teeth.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
              <CRow>
            <CCol lg={6} xl={6} xxl={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Dentures</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Replacements for missing teeth and surrounding tissues
   <br/>
   <FaChevronCircleRight/> Enhance your smile and facial appearance
   <br/>
   <FaChevronCircleRight/> Improved Chewing and Speech.
    <br/>
     <FaChevronCircleRight/> Support the structure of the face, preventing the cheeks and lips from sagging.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol lg={6} xl={6} xxl={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Tooth Extraction</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Remove teeth damaged beyond repair due to decay, disease, or trauma
   <br/>
   <FaChevronCircleRight/> Help alleviate the issue of crowdin
   <br/>
   <FaChevronCircleRight/> Gum disease can cause the teeth to loosen, thus affected teeth need to be extracted.
    <br/>
     <FaChevronCircleRight/> Both simple and surgical extraction done.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
              <CRow>
            <CCol lg={6} xl={6} xxl={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Cosmetic & Gum Treatment</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Teeth Whitening to make them lighter and remove discoloration and stains.
   <br/>
   <FaChevronCircleRight/> Deep-cleaning, non-surgical procedure, used to treat gum disease
   <br/>
   <FaChevronCircleRight/> Remove black spots or patches on the gums caused by excessive melanin
    <br/>
     <FaChevronCircleRight/> Reshape the gums and improve the appearance of the smile.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol lg={6} xl={6} xxl={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Orthodontic Treatment</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Improve irregularities of the teeth and jaw.
   <br/>
   <FaChevronCircleRight/>Take care of Crooked teeth, Overcrowded teeth, Overbites, Underbites and Crossbites
   <br/>
   <FaChevronCircleRight/>  Improves the appearance of your smile.
    <br/>
     <FaChevronCircleRight/> Enhances your oral health and function.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
          </CRow>
      </CContainer>
      <br/>
      
    <CContainer fuid style={{textAlign:'left'}}>
    <CRow>
        <CCol lg={12} xl={12} xxl={12} style={{textAlign:'center'}}>
        <CBadge style={mkm1}><MdContactSupport/>Contact</CBadge>
        </CCol>
          </CRow>
    <CRow>
       
       <CCol lg={12} xl={12} xxl={12}>
       
         <CBadge style={mkm}>Address</CBadge>
        
         <CBadge style={mkm1}>A462, Eldeco Udyan II, Lucknow, Uttar Pradesh 226014</CBadge>
         </CCol>
         <CCol lg={12} xl={12} xxl={12}>
       <CBadge style={mkm}>Mobile</CBadge>
       <CBadge style={mkm1}>06386658751</CBadge>
       </CCol>
       <CCol lg={12} xl={12} xxl={12}>
       <CBadge style={mkm}>Email</CBadge>
       <CBadge style={mkm1}>preranaops@gmail.com</CBadge>
       </CCol>
       </CRow>
    </CContainer>
      <CContainer fluid style={ftr}>
      <CRow>
      <CCol lg={12} xl={12} xxl={12}>
  <div>
    <CLink href="https://coreui.io" style={{color:'black'}}>@ Copyright 2024</CLink>
    <span>&copy; Maa Durga Dental Clinic.</span>
 
    <span style={{color:'#000000'}}>All rights reserved.</span>
    
  </div>
  </CCol>
  </CRow>
      </CContainer>
        <CContainer fluid style={cl_lg}>
            <CRow>
             <CCol  lg={12} xl={12} xxl={12}>
           <FaWhatsapp style={{color:'#006769',fontSize:'2.5em'}} />
           </CCol>
          
           </CRow>
            </CContainer>
     
      </>
    )}
       {isTabletOrMobile && (
         <>
       <Header/>
  <Navbar/>
 <CContainer fluid style={{backgroundImage:'linear-gradient(180deg, #FFFFFF,#FFFFFF)',padding:'1%', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
       <CRow>
       <CCol sm={6} md={6} lg={6}>
         <CCarousel  controls indicators dark>
         <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga1.jpeg"} alt="slide 1" style={sldr} />
  </CCarouselItem>
  
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga3.jpeg"} alt="slide 3" style={sldr} />
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga4.jpeg"} alt="slide 4" style={sldr} />
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga5.jpeg"} alt="slide 5" style={sldr} />
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga6.jpeg"} alt="slide 6" style={sldr} />
  </CCarouselItem>
  </CCarousel>
         </CCol>
         <CCol sm={6} md={6} lg={6}>
         <CContainer style={hji}>
      <CRow>
        <CCol sm={12} md={12} lg={12}>
        <CBadge style={mkm1}>Appointment</CBadge>
        </CCol>
          </CRow>
          <br/>
          <CContainer>
           <CRow>
             <CCol  lg={12} xl={12} xxl={12}>
             <CInputGroup size="lg">
                    
  <CInputGroupText style={yys}>Name</CInputGroupText>
  <CFormInput onBlur={chk_apt} className="apt_bx" style={yys1} placeholder="Enter Name" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
 
</CInputGroup>
<span className="lbls" style={apt_lbl}>Mandatory</span>
             </CCol>
      
             <CCol  lg={12} xl={12} xxl={12}>
             <CInputGroup size="lg">
  <CInputGroupText style={yys}>Mobile</CInputGroupText>
  <CFormInput onBlur={chk_apt1} className="apt_bx" style={yys1} placeholder="Enter Mobile No." aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
</CInputGroup>
<span className="lbls" style={apt_lbl1}>Mandatory</span>
             </CCol>
             <CCol  lg={12} xl={12} xxl={12}>
             <CInputGroup size="lg">
  <CInputGroupText style={yys}>Date</CInputGroupText>
  <CFormInput onBlur={chk_apt2} className="apt_bx" style={yys1} placeholder="Enter Mobile No." aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" type="date"/>
</CInputGroup>
<span className="lbls" style={apt_lbl2}>Mandatory</span>
             </CCol>
             <CCol  lg={12} xl={12} xxl={12}>
             <CInputGroup size="lg">
  <CInputGroupText style={yys}>Time</CInputGroupText>
  <CFormSelect onChange={hr_mng}  style={yys1x} id="zv3" aria-label="Default select example">
  <option>Select Time</option>
  </CFormSelect>&nbsp;&nbsp;&nbsp;
  <CFormSelect onChange={hr_mng2}  style={yys1x} id="zv4" aria-label="Default select example">
  <option>AM</option>
  <option>PM</option>
  </CFormSelect>
</CInputGroup>
<span className="lbls" style={apt_lbl3}>Mandatory</span>
<CBadge style={yysst}>Mon - Sat (Morning 10:00 AM - 01:00 PM) <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Evening 05:00 PM - 08:00 PM)</CBadge>
<CBadge style={yysst}>Mon - Sun (Morning 10:00 AM - 01:00 PM)</CBadge>
             </CCol>
             
           
             
             <CCol  lg={12} xl={12} xxl={12} style={{textAlign:'left',paddingTop:'2%'}}>
             <CFormCheck id="tc" inline style={rtgv} value="" label="I agree to terms & condition!!"/>
             <CButton color="link">Read T&C</CButton>
             </CCol>
             <br/>
             <CCol  lg={12} xl={12} xxl={12}>
             <CButton id="bk_btn" style={rgp} onClick={bk_apt} onMouseOver={bk_ovr} onMouseOut={bk_out}>Book Appointment</CButton>
             </CCol>
           </CRow>
          </CContainer>
      </CContainer>
         </CCol>
        </CRow>
        <br/>
        <CRow>
         <CCol sm={12} md={12} lg={12}>
         <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3562.4919236100573!2d80.93785900000002!3d26.760585000000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDQ1JzM4LjEiTiA4MMKwNTYnMTYuMyJF!5e0!3m2!1sen!2sin!4v1716192646046!5m2!1sen!2sin" width="100%" height="390"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
         </CCol>
       </CRow>
      </CContainer>
      <CContainer fluid style={hjit}>
        <CRow>
          <CCol lg={12} xl={12} xxl={12} style={{textAlign:'center'}}>
          <CBadge style={mkm1}><FcAbout/>About</CBadge>
          </CCol>
          <CCol lg={12} xl={12} xxl={12} style={{textAlign:'left',fontSize:'1.4em',fontWeight:'600'}}>
         Greetings from Maa Durga Dental Clinic,<br/>
         <p style={{textAlign:'center'}}><CImage fluid  src={"https://localhost/dental/maadurgadental/images/Maa_durga_dental_clinic.jpg"} alt="slide 4"  style={sldr} /></p><br/>
         <p><span style={pntr}><GiSevenPointedStar/></span>I am dedicated to ensure that your thirty two pearls keep shining bright and healthy.</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Your smile and healthy nutrition start right from the point of chewing your food efficiently.</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Patient satisfaction and regular feedbacks  are the constant inputs which i rely on to provide the best of services.</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Our dental clinic is equiped with state of art equipment</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Schedule/Re-Schedule appointments</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Cost effective dental health care</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Hassle free Time bound services</p> 
         <p><span style={pntr}><GiSevenPointedStar/></span>Post treatment offline & online consulting</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Your smile is source of inspiration.</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Premier dental clinic in Lucknow,Uttar Pradesh</p>
          </CCol>
        </CRow>
      </CContainer>
      <CContainer fluid style={hji}>
      <CRow>
        <CCol sm={12} md={12} lg={12} style={{textAlign:'center'}}>
        <CBadge style={mkm1}><FcProcess/>Procedure</CBadge>
        </CCol>
          </CRow>
          <CRow>
            <CRow>
            <CCol sm={6} md={6} lg={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>RCT</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Treat infection at the center of a tooth
   <br/>
   <FaChevronCircleRight/> Preserve the tooth without pain and infection
   <br/>
   <FaChevronCircleRight/> Comfortable chewing and maintaining normal biting force and sensation.
    <br/>
     <FaChevronCircleRight/> Protection of other teeth from excessive wear or strain
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol sm={6} md={6} lg={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Scaling</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Clear plaque and tartar (also known as calculus) that has accumulated on the teeth
   <br/>
   <FaChevronCircleRight/> To help maintain healthy gums and teeth
   <br/>
   <FaChevronCircleRight/> Prevent gum diseases such as gingivitis and periodontitis.
    <br/>
     <FaChevronCircleRight/> Safe and non-invasive procedure with minimal risks.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
              <CRow>
            <CCol sm={6} md={6} lg={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Dental Implants</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Popular and effective solution for replacing missing teeth
   <br/>
   <FaChevronCircleRight/> Regains the ability to eat virtually anything and can smile with confidence.
   <br/>
   <FaChevronCircleRight/> Improved Speech and comfort.
    <br/>
     <FaChevronCircleRight/>  Implants are very convenient and durable thus will last many years,
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol sm={6} md={6} lg={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top"  style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Crown & Bridge</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Restorative treatments used to repair the appearance and function of your teeth
   <br/>
   <FaChevronCircleRight/> Help maintain dental functionality and improve the overall aesthetics of your smile
   <br/>
   <FaChevronCircleRight/> Bridges span the space where the teeth are missing.
    <br/>
     <FaChevronCircleRight/> Porcelain or ceramic crowns can be matched to the color of your natural teeth.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
              <CRow>
            <CCol sm={6} md={6} lg={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Dentures</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Replacements for missing teeth and surrounding tissues
   <br/>
   <FaChevronCircleRight/> Enhance your smile and facial appearance
   <br/>
   <FaChevronCircleRight/> Improved Chewing and Speech.
    <br/>
     <FaChevronCircleRight/> Support the structure of the face, preventing the cheeks and lips from sagging.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol sm={6} md={6} lg={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Tooth Extraction</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Remove teeth damaged beyond repair due to decay, disease, or trauma
   <br/>
   <FaChevronCircleRight/> Help alleviate the issue of crowdin
   <br/>
   <FaChevronCircleRight/> Gum disease can cause the teeth to loosen, thus affected teeth need to be extracted.
    <br/>
     <FaChevronCircleRight/> Both simple and surgical extraction done.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
              <CRow>
            <CCol sm={6} md={6} lg={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Cosmetic & Gum Treatment</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Teeth Whitening to make them lighter and remove discoloration and stains.
   <br/>
   <FaChevronCircleRight/> Deep-cleaning, non-surgical procedure, used to treat gum disease
   <br/>
   <FaChevronCircleRight/> Remove black spots or patches on the gums caused by excessive melanin
    <br/>
     <FaChevronCircleRight/> Reshape the gums and improve the appearance of the smile.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol sm={6} md={6} lg={6} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Orthodontic Treatment</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Improve irregularities of the teeth and jaw.
   <br/>
   <FaChevronCircleRight/>Take care of Crooked teeth, Overcrowded teeth, Overbites, Underbites and Crossbites
   <br/>
   <FaChevronCircleRight/>  Improves the appearance of your smile.
    <br/>
     <FaChevronCircleRight/> Enhances your oral health and function.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
          </CRow>
      </CContainer>
      <br/>
      
      <CContainer fluid style={hji}>
      <CRow>
        <CCol lg={12} xl={12} xxl={12} style={{textAlign:'center'}}>
        <CBadge style={mkm1}><MdContactSupport/>Contact</CBadge>
        </CCol>
          </CRow>
        <CRow>
       
        <CCol sm={12} md={12} lg={12} style={hum}>
        <CRow>
       
        <CCol sm={12} md={12} lg={12}>
        
          <CBadge style={mkm}>Address</CBadge>
         
          <CBadge style={mkm1z}>A462, Eldeco Udyan II, Lucknow, Uttar Pradesh 226014</CBadge>
          </CCol>
          <CCol sm={12} md={12} lg={12}>
        <CBadge style={mkm}>Mobile</CBadge>
        <CBadge style={mkm1z}>06386658751</CBadge>
        </CCol>
        <CCol sm={12} md={12} lg={12}>
        <CBadge style={mkm}>Email</CBadge>
        <CBadge style={mkm1z}>preranaops@gmail.com</CBadge>
        </CCol>
        </CRow>
        </CCol>
       
          
        </CRow>
      </CContainer>
      <CContainer fluid style={ftr}>
      <CRow>
      <CCol lg={12} xl={12} xxl={12}>
  <div>
    <CLink href="https://coreui.io" style={{color:'black'}}>@ Copyright 2024</CLink>
    <span>&copy; Maa Durga Dental Clinic.</span>
 
    <span style={{color:'#000000'}}>All rights reserved.</span>
    
  </div>
  </CCol>
  </CRow>
      </CContainer>
      <CContainer fluid style={cl_lg1}>
            <CRow>
             <CCol  lg={12} xl={12} xxl={12}>
           <FaWhatsapp style={{color:'#006769',fontSize:'2.5em'}} />
           </CCol>
          
           </CRow>
            </CContainer>
      </>
    )}
     {isMobile && (
        <>
        <Header/>
        <Navbar/>
     <CContainer fluid style={{backgroundImage:'linear-gradient(180deg, #FFFFFF,#FFFFFF)',padding:'1%', boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}>
       <CRow>
       <CCol xs={12}>
         <CCarousel  controls indicators dark>
         <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga1.jpeg"} alt="slide 1" style={sldr} />
  </CCarouselItem>
  
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga3.jpeg"} alt="slide 3" style={sldr} />
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga4.jpeg"} alt="slide 4" style={sldr} />
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga5.jpeg"} alt="slide 5" style={sldr} />
  </CCarouselItem>
  <CCarouselItem>
    <CImage fluid className="d-block w-100" src={"https://localhost/dental/maadurgadental/images/durga6.jpeg"} alt="slide 6" style={sldr} />
  </CCarouselItem>
  </CCarousel>
         </CCol>
        </CRow>
        <br/>
        <CRow>
         <CCol xs={12}>
         <CContainer style={hji}>
      <CRow>
        <CCol sm={12} md={12} lg={12}>
        <CBadge style={mkm1}>Appointment</CBadge>
        </CCol>
          </CRow>
          <br/>
          <CContainer>
           <CRow>
             <CCol  lg={12} xl={12} xxl={12}>
             <CInputGroup size="lg">
                    
  <CInputGroupText style={yys}>Name</CInputGroupText>
  <CFormInput onBlur={chk_apt} className="apt_bx" style={yys1} placeholder="Enter Name" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
 
</CInputGroup>
<span className="lbls" style={apt_lbl}>Mandatory</span>
             </CCol>
      
             <CCol  lg={12} xl={12} xxl={12}>
             <CInputGroup size="lg">
  <CInputGroupText style={yys}>Mobile</CInputGroupText>
  <CFormInput onBlur={chk_apt1} className="apt_bx" style={yys1} placeholder="Enter Mobile No." aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
</CInputGroup>
<span className="lbls" style={apt_lbl1}>Mandatory</span>
             </CCol>
             <CCol  lg={12} xl={12} xxl={12}>
             <CInputGroup size="lg">
  <CInputGroupText style={yys}>Date</CInputGroupText>
  <CFormInput onBlur={chk_apt2} className="apt_bx" style={yys1} placeholder="Enter Mobile No." aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg" type="date"/>
</CInputGroup>
<span className="lbls" style={apt_lbl2}>Mandatory</span>
             </CCol>
             <CCol  lg={12} xl={12} xxl={12}>
             <CInputGroup size="lg">
  <CInputGroupText style={yys}>Time</CInputGroupText>
  <CFormSelect onChange={hr_mng}  style={yys1x} id="zv3" aria-label="Default select example">
  <option>Select Time</option>
  </CFormSelect>&nbsp;&nbsp;&nbsp;
  <CFormSelect onChange={hr_mng2}  style={yys1x} id="zv4" aria-label="Default select example">
  <option>AM</option>
  <option>PM</option>
  </CFormSelect>
</CInputGroup>
<span className="lbls" style={apt_lbl3}>Mandatory</span>
<CBadge style={yyss}>Mon - Sat (Morning 10:00 AM - 01:00 PM) <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Evening 05:00 PM - 08:00 PM)</CBadge>
<CBadge style={yyss}>Mon - Sun (Morning 10:00 AM - 01:00 PM)</CBadge>
             </CCol>
             
           
             
             <CCol  lg={12} xl={12} xxl={12} style={{textAlign:'left',paddingTop:'2%'}}>
             <CFormCheck id="tc" inline style={rtgv} value="" label="I agree to terms & condition!!"/>
             <CButton color="link">Read T&C</CButton>
             </CCol>
             <br/>
             <CCol  lg={12} xl={12} xxl={12}>
             <CButton id="bk_btn" style={rgp} onClick={bk_apt} onMouseOver={bk_ovr} onMouseOut={bk_out}>Book Appointment</CButton>
             </CCol>
           </CRow>
          </CContainer>
      </CContainer>
         </CCol>
       </CRow>
      </CContainer>
    
      <CContainer fluid style={hjit}>
        <CRow>
          <CCol lg={12} xl={12} xxl={12} style={{textAlign:'center'}}>
          <CBadge style={mkm1}><FcAbout/>About</CBadge>
          </CCol>
          <CCol lg={12} xl={12} xxl={12} style={{textAlign:'left',fontSize:'1.4em',fontWeight:'600'}}>
         Greetings from Maa Durga Dental Clinic,<br/>
         <p style={{textAlign:'center'}}><CImage fluid  src={"https://localhost/dental/maadurgadental/images/Maa_durga_dental_clinic.jpg"} alt="slide 4"  style={sldr} /> </p><br/>
         <p><span style={pntr}><GiSevenPointedStar/></span>I am dedicated to ensure that your thirty two pearls keep shining bright and healthy.</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Your smile and healthy nutrition start right from the point of chewing your food efficiently.</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Patient satisfaction and regular feedbacks  are the constant inputs which i rely on to provide the best of services.</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Our dental clinic is equiped with state of art equipment</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Schedule/Re-Schedule appointments</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Cost effective dental health care</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Hassle free Time bound services</p> 
         <p><span style={pntr}><GiSevenPointedStar/></span>Post treatment offline & online consulting</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Your smile is source of inspiration.</p>
         <p><span style={pntr}><GiSevenPointedStar/></span>Premier dental clinic in Lucknow,Uttar Pradesh</p>
          </CCol>
        </CRow>
      </CContainer>
      <CContainer fluid style={hji}>
      <CRow>
        <CCol sm={12} md={12} lg={12} style={{textAlign:'center'}}>
        <CBadge style={mkm1}>Procedure</CBadge>
        </CCol>
          </CRow>
          <CRow>
            <CRow>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>RCT</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Treat infection at the center of a tooth
   <br/>
   <FaChevronCircleRight/> Preserve the tooth without pain and infection
   <br/>
   <FaChevronCircleRight/> Comfortable chewing and maintaining normal biting force and sensation.
    <br/>
     <FaChevronCircleRight/> Protection of other teeth from excessive wear or strain
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Scaling</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Clear plaque and tartar (also known as calculus) that has accumulated on the teeth
   <br/>
   <FaChevronCircleRight/> To help maintain healthy gums and teeth
   <br/>
   <FaChevronCircleRight/> Prevent gum diseases such as gingivitis and periodontitis.
    <br/>
     <FaChevronCircleRight/> Safe and non-invasive procedure with minimal risks.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
              <CRow>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Dental Implants</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Popular and effective solution for replacing missing teeth
   <br/>
   <FaChevronCircleRight/> Regains the ability to eat virtually anything and can smile with confidence.
   <br/>
   <FaChevronCircleRight/> Improved Speech and comfort.
    <br/>
     <FaChevronCircleRight/>  Implants are very convenient and durable thus will last many years,
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top"  style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Crown & Bridge</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Restorative treatments used to repair the appearance and function of your teeth
   <br/>
   <FaChevronCircleRight/> Help maintain dental functionality and improve the overall aesthetics of your smile
   <br/>
   <FaChevronCircleRight/> Bridges span the space where the teeth are missing.
    <br/>
     <FaChevronCircleRight/> Porcelain or ceramic crowns can be matched to the color of your natural teeth.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
              <CRow>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Dentures</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Replacements for missing teeth and surrounding tissues
   <br/>
   <FaChevronCircleRight/> Enhance your smile and facial appearance
   <br/>
   <FaChevronCircleRight/> Improved Chewing and Speech.
    <br/>
     <FaChevronCircleRight/> Support the structure of the face, preventing the cheeks and lips from sagging.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Tooth Extraction</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Remove teeth damaged beyond repair due to decay, disease, or trauma
   <br/>
   <FaChevronCircleRight/> Help alleviate the issue of crowdin
   <br/>
   <FaChevronCircleRight/> Gum disease can cause the teeth to loosen, thus affected teeth need to be extracted.
    <br/>
     <FaChevronCircleRight/> Both simple and surgical extraction done.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
              <CRow>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Cosmetic & Gum Treatment</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Teeth Whitening to make them lighter and remove discoloration and stains.
   <br/>
   <FaChevronCircleRight/> Deep-cleaning, non-surgical procedure, used to treat gum disease
   <br/>
   <FaChevronCircleRight/> Remove black spots or patches on the gums caused by excessive melanin
    <br/>
     <FaChevronCircleRight/> Reshape the gums and improve the appearance of the smile.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
            <CCol xs={12} style={ado}>
            <CCard style={{ width: '100%',padding:'1%' }}>
  <CCardImage orientation="top" style={ghj} />
  <CCardBody>
    <CCardTitle style={dgg}>Orthodontic Treatment</CCardTitle>
    <CCardText style={{textAlign:'left'}}> 
    <FaChevronCircleRight/> Improve irregularities of the teeth and jaw.
   <br/>
   <FaChevronCircleRight/>Take care of Crooked teeth, Overcrowded teeth, Overbites, Underbites and Crossbites
   <br/>
   <FaChevronCircleRight/>  Improves the appearance of your smile.
    <br/>
     <FaChevronCircleRight/> Enhances your oral health and function.
    </CCardText>
    
  </CCardBody>
</CCard>
            </CCol>
              </CRow>
          </CRow>
      </CContainer>
      <br/>
      
      <CContainer fluid style={hji}>
        <CRow>
      
        <CCol sm={12} md={12} lg={12} style={hum}>
        <CRow>
       
        <CCol sm={12} md={12} lg={12}>
        
          <CBadge style={mkmb}>Address</CBadge>
         
          <CBadge style={mkm1b}>A462, Eldeco Udyan II, Lucknow,<br/> Uttar Pradesh 226014</CBadge>
          </CCol>
          <CCol sm={12} md={12} lg={12}>
        <CBadge style={mkmb}>Mobile</CBadge>
        <CBadge style={mkm1b}>06386658751</CBadge>
        </CCol>
        <CCol sm={12} md={12} lg={12}>
        <CBadge style={mkmb}>Email</CBadge>
        <CBadge style={mkm1b}>preranaops@gmail.com</CBadge>
        </CCol>
        </CRow>
        </CCol>
       
          
        </CRow>
      </CContainer>
      <CContainer fluid style={ftr}>
      <CRow>
       <CCol xs={12} style={{boxShadow:'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px'}}>
         <CNav as="nav">
 <CNavLink href="https://www.facebook.com/profile.php?id=61559487706734" style={header_c1}><TiSocialFacebookCircular /></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialInstagramCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialLinkedinCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiTwitterXFill/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiYoutubeLine /></CNavLink>
</CNav>
</CCol>
         </CRow>
      <CRow>
      <CCol lg={12} xl={12} xxl={12}>
  <div>
    <CLink href="https://coreui.io" style={{color:'black'}}>@ Copyright 2024</CLink>
    <span>&copy; Maa Durga Dental Clinic.</span>
 
    <span style={{color:'#000000'}}>All rights reserved.</span>
    
  </div>
  </CCol>
  </CRow>
      </CContainer>
 
      </>
    )}
    </div>
  );
}

export default Home;