import logo from './logo.svg';
import './App.css';
import { useMediaQuery } from 'react-responsive';
import {CContainer} from '@coreui/react';
import JSAlert from 'js-alert';
import $ from "jquery";
import Home from './home';
 import Login from './login';
import React,{ useState, useEffect }  from 'react';
import { Route, Routes,BrowserRouter as Router } from 'react-router-dom';
function App() {
  var abb=window.location.href;
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 999px)'
  })
 
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 998.9px) and (min-width:600px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 599px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
  
  useEffect(() => {
      setIsLoaded(true);
      
  }, []);
  
  useEffect(() => {//on load
    if (isLoaded) {
    
      
    }
  
}, [isLoaded]);

  return (
    <div className="App" style={{fontFamily:'lucida',backgroundImage:'linear-gradient(90deg, white)'}}>
     {isDesktopOrLaptop && (
      <>
     <Router>
     <Routes>
         <Route path='/' element={<Home/>} />
        
         <Route path='/login' element={<Login/>} />
       </Routes>
       </Router>
      </>
    )}
       {isTabletOrMobile && (
         <>
    <Router>
     <Routes>
         <Route path='/' element={<Home/>} />
        
         <Route path='/login' element={<Login/>} />
       </Routes>
       </Router>
     
      </>
    )}
     {isMobile && (
        <>
     <Router>
     <Routes>
         <Route path='/' element={<Home/>} />
         
         <Route path='/login' element={<Login/>} />
       </Routes>
       </Router>
      
      </>
    )}
    </div>
  );
}

export default App;
