import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiCustomerService2Fill,RiHealthBookFill,RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import {FaEye,FaUserCircle,FaHospitalAlt,FaCommentMedical,FaHandHoldingMedical,FaAmbulance,FaCalendarCheck,FaRegWindowClose,FaCaretRight,FaCaretDown,FaHandHoldingHeart,FaHamburger,FaSearchPlus}  from "react-icons/fa";
import { FaBedPulse,FaUserDoctor } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdAccountCircle,MdEmergency,MdVideoChat,MdChat,MdOutlineEmail,MdOutlineUnsubscribe,MdLocalPharmacy,MdDataset,MdOnlinePrediction,MdFilterList,MdOutlineEmergency,MdOutlineWifiCalling3,MdAddCall,MdOutlinePrivacyTip,MdContactSupport,MdOutlineAddBusiness,MdOutlineFeedback } from "react-icons/md";
import { GrScheduleNew } from "react-icons/gr";
import { GiHealthPotion } from "react-icons/gi";
import { FcDepartment,FcNews } from "react-icons/fc";
import { BsTelephonePlusFill } from "react-icons/bs";
import React,{ useState, useEffect, Component }  from 'react'
import { useMediaQuery } from 'react-responsive'
import JSAlert from 'js-alert';


import $ from "jquery";


function Login() {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 999px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px) and (min-width:576px)' })
      const isMobile = useMediaQuery({ query: '(max-width: 576px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
      const [isLoaded, setIsLoaded] = useState(false);
      const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
      
      useEffect(() => {
          setIsLoaded(true);
      }, []);
      
      useEffect(() => {//on load
          if (isLoaded) {
         
           
           
          }
        });
      const apoe={
        padding:'1%',
        height:'auto',
        width:'100%',
      
      }  
      const pole={
          color:'black',
        padding:'1%',
        boxShadow:'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        backgroundImage:'linear-gradient(0deg, #FFFFFF,#FFFFFF)'
      } 
      const dsn={
        backgroundImage:'linear-gradient(0deg, #FFFFFF,#FFFFFF)',
        color:'black',
        border:'none'
      }
      const lg_bx={
        border:'none',
        borderBottom:'1px outset black',
        
      }
      const lss={
        padding:'1%',
        fontSize:'96%'
      }
      function erp_dsh(e){
        e.preventDefault();
        var mch=window.location.href;
        mch=mch.replace("lgn","erp")
        var dfg=mch;
        window.location.href=dfg;
        return false;
      }
      
      return (
        <div className="hdr">
       {isDesktopOrLaptop && (
           <>
          
          <CContainer fluid id="krx" style={apoe}>
         <CContainer fluid  style={pole}>
        <CRow>
          <CCol lg={2} xl={2} xxl={2} style={{textAlign:'center'}}>
        
          <CImage  fluid rounded src="https://www.maadurgadental.com/images/icon.png" width={100} height={100} />
          </CCol>
          <CCol lg={10} xl={10} xxl={10} style={{visibility:'hidden'}}>
          a
          </CCol>
          </CRow>
          <br/>
          <CContainer style={{padding:'1%',boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}}>
            <CRow>
          <CCol lg={10} xl={10} xxl={10} style={lss}>
          <CInputGroup size="lg">
  <CInputGroupText style={dsn}>Username</CInputGroupText>
  <CFormInput className="rf_lg" style={lg_bx} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
</CInputGroup>
          </CCol>
          <CCol lg={2} xl={2} xxl={2} style={{visibility:'hidden'}}>
          a
          </CCol>
          </CRow>
          <CRow>
          <CCol lg={10} xl={10} xxl={10} style={lss}>
          <CInputGroup size="lg">
  <CInputGroupText style={dsn}>Password</CInputGroupText>
  <CFormInput className="rf_lg" style={lg_bx} type="password" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
</CInputGroup>
          </CCol>
          <CCol lg={2} xl={2} xxl={2}>
          <CInputGroupText style={dsn}><FaEye/></CInputGroupText>
          </CCol>
          </CRow>
          <CRow>
            <CCol lg={12} xl={12} xxl={12}>
            <CButton onClick={erp_dsh}  size="lg" style={{textAlign:'center',height:'105%',width:'80%',backgroundImage:'linear-gradient(90deg, #153448,#4793AF)',color:'white'}}><FaUserCircle/> Login</CButton>
          </CCol>
          
              </CRow>
              <CRow>
              <CCol lg={8} xl={8} xxl={8} style={{visibility:'hidden'}}>
          a
          </CCol>
            <CCol lg={4} xl={4} xxl={4}>
            <CButton style={{fontWeight:'700',fontSize:'96%'}} color="link">Forgot Username/Password</CButton>
          </CCol>
          
              </CRow>
          </CContainer>
         </CContainer>
         </CContainer>
         <CContainer fluid id="rxiof" style={{backgroundImage:'linear-gradient(90deg, white)',padding:'1%',visibility:'hidden',display:'none',height:'100%'}}>
        
         </CContainer>
           </>
           
       )}
       {isTabletOrMobile && (
             <>
            <CContainer fluid style={apoe}>
         <CContainer  style={pole}>
        <CRow>
          <CCol sm={2} md={2} lg={2} style={{textAlign:'center'}}>
        
          <CImage  fluid rounded src="https://www.maadurgadental.com/images/icon.png" width={100} height={100} />
          </CCol>
          <CCol sm={10} md={10} lg={10} style={{visibility:'hidden'}}>
          a
          </CCol>
          </CRow>
          <br/>
          <CContainer style={{padding:'1%',boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}}>
            <CRow>
          <CCol sm={10} md={10} lg={10} style={lss}>
          <CInputGroup size="mb">
  <CInputGroupText style={dsn}>Username</CInputGroupText>
  <CFormInput className="rf_lg" style={lg_bx} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
</CInputGroup>
          </CCol>
          <CCol sm={2} md={2} lg={2} style={{visibility:'hidden'}}>
          a
          </CCol>
          </CRow>
          <CRow>
          <CCol sm={10} md={10} lg={10} style={lss}>
          <CInputGroup size="mb">
  <CInputGroupText style={dsn}>Password</CInputGroupText>
  <CFormInput className="rf_lg" style={lg_bx} type="password" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"/>
</CInputGroup>
          </CCol>
          <CCol sm={2} md={2} lg={2}>
          <CInputGroupText style={dsn}><FaEye/></CInputGroupText>
          </CCol>
          </CRow>
          <CRow>
            <CCol sm={12} md={12} lg={12}>
            <CButton  onClick={erp_dsh} size="mb" style={{textAlign:'center',height:'105%',width:'80%',backgroundImage:'linear-gradient(90deg, #153448,#4793AF)',color:'white'}}><FaUserCircle/> Login</CButton>
          </CCol>
          
              </CRow>
              <CRow>
              <CCol sm={8} md={8} lg={8} style={{visibility:'hidden'}}>
          a
          </CCol>
            <CCol sm={4} md={4} lg={4}>
            <CButton style={{fontWeight:'700',fontSize:'96%'}} color="link">Forgot Username/Password</CButton>
          </CCol>
          
              </CRow>
          </CContainer>
         </CContainer>
         </CContainer>
             </>
        )}
         {isMobile && (
        <>
           <CContainer fluid style={apoe}>
         <CContainer  style={pole}>
        <CRow>
          <CCol xs={12} style={{textAlign:'center'}}>
        
          <CImage  fluid rounded src="https://www.maadurgadental.com/images/icon.png" width={100} height={100} />
          </CCol>
          
          </CRow>
          <br/>
          <CContainer style={{padding:'1%',boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}}>
            <CRow>
          <CCol xs={12} style={lss}>
          <CInputGroup size="sm">
  <CInputGroupText style={dsn}>Username</CInputGroupText>
  <CFormInput className="rf_lg" style={lg_bx} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"/>
</CInputGroup>
          </CCol>
          
          </CRow>
          <CRow>
          <CCol xs={12} style={lss}>
          <CInputGroup size="sm">
  <CInputGroupText style={dsn}>Password</CInputGroupText>
  <CFormInput className="rf_lg" style={lg_bx} type="password" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm"/>
<CInputGroupText style={dsn}><FaEye/></CInputGroupText>
</CInputGroup>
          </CCol>
         
          </CRow>
          <CRow>
            <CCol xs={12}>
            <CButton onClick={erp_dsh}  size="sm" style={{textAlign:'center',height:'105%',width:'80%',backgroundImage:'linear-gradient(90deg, #153448,#4793AF)',color:'white'}}><FaUserCircle/> Login</CButton>
          </CCol>
          
              </CRow>
              <CRow>
              
            <CCol xs={12}>
            <CButton style={{fontWeight:'700',fontSize:'96%'}} color="link">Forgot Username/Password</CButton>
          </CCol>
          
              </CRow>
          </CContainer>
         </CContainer>
         </CContainer> 
        </>
        )}
        </div>
                     );
    }
export default Login;