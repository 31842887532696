import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import $ from "jquery";
import { TfiFaceSmile } from "react-icons/tfi";
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import { GiSelfLove } from "react-icons/gi";
import { FaAutoprefixer, FaHamburger, FaUserAlt } from "react-icons/fa";
import { FcAbout,FcProcess } from "react-icons/fc";
import { VscPreview } from "react-icons/vsc";
import { MdContactSupport,MdAddIcCall } from "react-icons/md";
function Header() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 999px)'
  })
 
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 998.9px) and (min-width:600px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 599px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const hdr={
    height:'1%',
    padding:'1%',
    paddingTop:'0%',
    boxShadow:'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;',
    backgroundImage:'linear-gradient(180deg, #FAF4B7,#F6ECBF,#FAF4B7)',
    fontWeight:'600',
    textAlign:'left',
    fontSize:'0.99rem',
    width:'100%'
  }
  const mnu1={
    border:'none',
    color:'black',
    fontWeight:'600',
    paddingRight:'1.0%',
    textAlign:'center',
    fontSize:'0.90em',
    cursor:'pointer'
  }
  const mnu1x={
    border:'none',
    color:'black',
    fontWeight:'600',
    paddingRight:'1.0%',
    textAlign:'center',
    fontSize:'0.70em',
    cursor:'pointer'
  }
  const header_c1={
    textAlign:'right',
    color:'black',
    
  }
  const mnuz={
    textAlign:'right',
    fontSize:'1.3em',
    padding:'1%',
  }
  
  const rmj={
    border:'1px outset black',
    borderRadius:'50%'
  }
   function j1(e){
     e.preventDefault();
     document.getElementsByClassName("mnu_frm")[0].style.textDecoration="underline";
     document.getElementsByClassName("mnu_frm")[0].style.color="blue";
     return false;
   }
   function k1(e){
    e.preventDefault();
    document.getElementsByClassName("mnu_frm")[0].style.textDecoration="none";
    document.getElementsByClassName("mnu_frm")[0].style.color="black";
    return false;
  }
  function j2(e){
    e.preventDefault();
    document.getElementsByClassName("mnu_frm")[1].style.textDecoration="underline";
    document.getElementsByClassName("mnu_frm")[1].style.color="blue";
    return false;
  }
  function k2(e){
   e.preventDefault();
   document.getElementsByClassName("mnu_frm")[1].style.textDecoration="none";
   document.getElementsByClassName("mnu_frm")[1].style.color="black";
   return false;
 }
 function j3(e){
  e.preventDefault();
  document.getElementsByClassName("mnu_frm")[2].style.textDecoration="underline";
  document.getElementsByClassName("mnu_frm")[2].style.color="blue";
  return false;
}
function k3(e){
 e.preventDefault();
 document.getElementsByClassName("mnu_frm")[2].style.textDecoration="none";
 document.getElementsByClassName("mnu_frm")[2].style.color="black";
 return false;
}
function j4(e){
  e.preventDefault();
  document.getElementsByClassName("mnu_frm")[3].style.textDecoration="underline";
  document.getElementsByClassName("mnu_frm")[3].style.color="blue";
  return false;
}
function k4(e){
 e.preventDefault();
 document.getElementsByClassName("mnu_frm")[3].style.textDecoration="none";
 document.getElementsByClassName("mnu_frm")[3].style.color="black";
 return false;
}
function j5(e){
  e.preventDefault();
  document.getElementsByClassName("mnu_frm")[4].style.textDecoration="underline";
  document.getElementsByClassName("mnu_frm")[4].style.color="blue";
  return false;
}
function k5(e){
 e.preventDefault();
 document.getElementsByClassName("mnu_frm")[4].style.textDecoration="none";
 document.getElementsByClassName("mnu_frm")[4].style.color="black";
 return false;
}
function lgn_wndw(e){
  e.preventDefault();
  var mch=window.location.href;
  mch=mch+"&lgn"
  window.location.href=mch;
  return false;
}
  return (
    <div className="header">
     {isDesktopOrLaptop && (
      <>
    <CContainer fluid style={hdr}>
    <CRow>
        <CCol lg={4} xl={4} xxl={4} style={{textAlign:'left'}}>
        <CImage fluid rounded thumbnail src="https://localhost/dental/maadurgadental/images/icon.png" width={50} height={50} />
        
        <CBadge  style={{fontSize:'1.4em',color:'black',fontWeight:'700'}}>We <GiSelfLove /> to See You <TfiFaceSmile />!</CBadge>
        </CCol>
       
       <CCol lg={4} xl={4} xxl={4}  style={{textAlign:'left',border:'none',padding:'1%'}}>
       <CBadge  style={{fontSize:'1.4em',color:'#E72929',fontWeight:'600',fontFamily:'Brush Script MT'}}>Maa Durga</CBadge>
       <CBadge  style={{fontSize:'1.6em',color:'#E72929',fontWeight:'700',fontFamily:'Lucida'}}>Dental Clinic</CBadge>
       <CBadge  style={{fontSize:'1.4em',color:'#E72929',fontWeight:'500',fontFamily:'Lucida',boxShadow:'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px',cursor:'pointer'}}><MdAddIcCall/> 6386658751</CBadge>
       </CCol>
       <CCol lg={4} xl={4} xxl={4}  style={{textAlign:'left'}}>
       <CNav as="nav">
 <CNavLink href="https://www.facebook.com/profile.php?id=61559487706734" style={header_c1} ><TiSocialFacebookCircular /></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialInstagramCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialLinkedinCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiTwitterXFill/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiYoutubeLine /></CNavLink>
</CNav>
       </CCol>
        </CRow>

    </CContainer>
  
      </>
    )}
       {isTabletOrMobile && (
         <>
        
      <CContainer fluid style={hdr}>
    <CRow>
        <CCol sm={6} md={6} lg={6} style={{textAlign:'left'}}>
        <CImage fluid rounded thumbnail src="https://localhost/dental/maadurgadental/images/icon.png" width={50} height={50} />
       
        <CBadge  style={{fontSize:'1.3em',color:'black',fontWeight:'700'}}>We <GiSelfLove /> to See You <TfiFaceSmile />!</CBadge>
        </CCol>
      
       <CCol sm={6} md={6} lg={6} style={{textAlign:'right'}}>
       <CNav as="nav">
 <CNavLink href="https://www.facebook.com/profile.php?id=61559487706734" style={header_c1}><TiSocialFacebookCircular /></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialInstagramCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><TiSocialLinkedinCircular/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiTwitterXFill/></CNavLink>
 <CNavLink href="#" style={header_c1}><RiYoutubeLine /></CNavLink>
</CNav>
       </CCol>
       </CRow>
       <CRow>
       <CCol sm={12} md={12} lg={12} style={{textAlign:'center'}}>
       <CBadge  style={{fontSize:'1.4em',color:'#E72929',fontWeight:'600',fontFamily:'Brush Script MT'}}>Maa Durga</CBadge>
       <CBadge  style={{fontSize:'1.6em',color:'#E72929',fontWeight:'700',fontFamily:'Lucida'}}>Dental Clinic</CBadge>
       <CBadge  style={{fontSize:'1.4em',color:'#E72929',fontWeight:'500',fontFamily:'Lucida',boxShadow:'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px',cursor:'pointer'}}><MdAddIcCall/> 6386658751</CBadge>
       </CCol>
        </CRow>

    </CContainer>
   
      </>
    )}
     {isMobile && (
        <>
        
     <CContainer fluid style={hdr}>
    <CRow>
    <CCol xs={12} style={{textAlign:'left',padding:'0.8%'}}>
        <CImage fluid rounded thumbnail src="https://localhost/dental/maadurgadental/images/icon.png" width={50} height={50} />
       
        <CBadge  style={{fontSize:'1.5em',color:'black',fontWeight:'700'}}>We <GiSelfLove /> to See You <TfiFaceSmile />!</CBadge>
       
       
        </CCol>
        <CCol xs={12} style={{textAlign:'center'}}>
        <CBadge  style={{fontSize:'1.4em',color:'#E72929',fontWeight:'600',fontFamily:'Brush Script MT'}}>Maa Durga</CBadge>
       <CBadge  style={{fontSize:'1.6em',color:'#E72929',fontWeight:'700',fontFamily:'Lucida'}}>Dental Clinic</CBadge>
       <CBadge  style={{fontSize:'1.4em',color:'#E72929',fontWeight:'500',fontFamily:'Lucida',boxShadow:'rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.9) 0px 0px 0px 1px',cursor:'pointer'}}><MdAddIcCall/> 6386658751</CBadge>
     
        </CCol>
      </CRow>
      </CContainer>
      <CContainer fluid style={{padding:'1%'}}><iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3562.4919236100573!2d80.93785900000002!3d26.760585000000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDQ1JzM4LjEiTiA4MMKwNTYnMTYuMyJF!5e0!3m2!1sen!2sin!4v1716192646046!5m2!1sen!2sin" width="100%" height="60"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></CContainer>
      </>
    )}
    </div>
  );
}

export default Header;