import { Link } from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useMediaQuery } from 'react-responsive';
import JSAlert from 'js-alert';
import { CAvatar,CFooter,CLink,CWidgetStatsA,CCarouselCaption,CCarousel,CCarouselItem,CDropdownDivider,CListGroupItem,CListGroup,CButton,CBadge,CInputGroupText,CFormInput,CInputGroup,CImage,CDropdownToggle,CDropdownItem,CDropdownMenu,CDropdown,CRow,CCol,CContainer,CNavLink,CNav,CHeader,CHeaderBrand } from '@coreui/react';
import $ from "jquery";
import { TfiFaceSmile } from "react-icons/tfi";
import { TiSocialFacebookCircular,TiSocialInstagramCircular,TiSocialLinkedinCircular } from "react-icons/ti";
import {RiTwitterXFill,RiYoutubeLine} from "react-icons/ri";
import { GiSelfLove } from "react-icons/gi";
import { FaAutoprefixer, FaHamburger, FaUserAlt } from "react-icons/fa";
import { FcAbout,FcProcess } from "react-icons/fc";
import { VscPreview } from "react-icons/vsc";
import { MdContactSupport } from "react-icons/md";

function Navbar() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 999px)'
  })
 
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 998.9px) and (min-width:600px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 599px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const mnuz={
    textAlign:'right',
    fontSize:'1.3em',
    padding:'1%',
  }
  
  const rmj={
    border:'1px outset black',
    borderRadius:'50%'
  }
  const rmjt={
    border:'1px outset black',
    borderRadius:'50%'
  }
  const mnu1={
    border:'none',
    color:'black',
    fontWeight:'600',
    paddingRight:'1.0%',
    textAlign:'center',
    fontSize:'0.90em',
    cursor:'pointer'
  }
  const mnu1_tb={
    border:'none',
    color:'black',
    fontWeight:'600',
    paddingRight:'1.0%',
    textAlign:'center',
    fontSize:'0.71em',
    cursor:'pointer'
  }
  const mnu1x={
    border:'none',
    color:'black',
    fontWeight:'600',
    paddingRight:'1.0%',
    textAlign:'center',
    fontSize:'0.70em',
    cursor:'pointer'
  }
  const header_c1={
    textAlign:'right',
    color:'black',
fontSize:'0.92em'
    
  }
  function j1(e){
    e.preventDefault();
    document.getElementsByClassName("mnu_frm")[0].style.textDecoration="underline";
    document.getElementsByClassName("mnu_frm")[0].style.color="blue";
    return false;
  }
  function k1(e){
   e.preventDefault();
   document.getElementsByClassName("mnu_frm")[0].style.textDecoration="none";
   document.getElementsByClassName("mnu_frm")[0].style.color="black";
   return false;
 }
 function j2(e){
   e.preventDefault();
   document.getElementsByClassName("mnu_frm")[1].style.textDecoration="underline";
   document.getElementsByClassName("mnu_frm")[1].style.color="blue";
   return false;
 }
 function k2(e){
  e.preventDefault();
  document.getElementsByClassName("mnu_frm")[1].style.textDecoration="none";
  document.getElementsByClassName("mnu_frm")[1].style.color="black";
  return false;
}
function j3(e){
 e.preventDefault();
 document.getElementsByClassName("mnu_frm")[2].style.textDecoration="underline";
 document.getElementsByClassName("mnu_frm")[2].style.color="blue";
 return false;
}
function k3(e){
e.preventDefault();
document.getElementsByClassName("mnu_frm")[2].style.textDecoration="none";
document.getElementsByClassName("mnu_frm")[2].style.color="black";
return false;
}
function j4(e){
 e.preventDefault();
 document.getElementsByClassName("mnu_frm")[3].style.textDecoration="underline";
 document.getElementsByClassName("mnu_frm")[3].style.color="blue";
 return false;
}
function k4(e){
e.preventDefault();
document.getElementsByClassName("mnu_frm")[3].style.textDecoration="none";
document.getElementsByClassName("mnu_frm")[3].style.color="black";
return false;
}
function j5(e){
  e.preventDefault();
  document.getElementsByClassName("mnu_frm")[4].style.textDecoration="underline";
  document.getElementsByClassName("mnu_frm")[4].style.color="blue";
  return false;
}
function k5(e){
 e.preventDefault();
 document.getElementsByClassName("mnu_frm")[4].style.textDecoration="none";
 document.getElementsByClassName("mnu_frm")[4].style.color="black";
 return false;
}
  return (
    <div className="nvr">
    {isDesktopOrLaptop && (
    <nav>
      <CContainer style={mnuz}>
   <CRow>
   <CCol  lg={3} xl={3} xxl={3}><CListGroupItem><span className="mnu_frm" onMouseOver={j1} onMouseOut={k1} style={mnu1}><FcAbout style={rmj}/>About</span></CListGroupItem></CCol> 
    <CCol  lg={3} xl={3} xxl={3}><CListGroupItem><span className="mnu_frm" onMouseOver={j2} onMouseOut={k2} style={mnu1}><FcProcess style={rmj}/>Procedure</span></CListGroupItem></CCol> 
    <CCol  lg={2} xl={2} xxl={2}><CListGroupItem><span className="mnu_frm" onMouseOver={j3} onMouseOut={k3} style={mnu1}><MdContactSupport style={rmj}/>Contact</span></CListGroupItem></CCol> 
   <CCol  lg={2} xl={2} xxl={2}><CListGroupItem><span className="mnu_frm" onMouseOver={j4} onMouseOut={k4} style={mnu1}><VscPreview style={rmj}/>Reviews</span></CListGroupItem></CCol> 
    <CCol  lg={2} xl={2} xxl={2}><CListGroupItem><span  className="mnu_frm" onMouseOver={j5} onMouseOut={k5} style={mnu1}><FaUserAlt style={rmj}/><Link  style={{color:'black',textDecoration:'none'}} to="/login">Login</Link></span></CListGroupItem></CCol> 
      </CRow>
 </CContainer>
    </nav>
     )}
     {isTabletOrMobile && (
       <nav>
       <CContainer style={mnuz}>
    <CRow>
    <CCol  sm={3} md={3} lg={3}><CListGroupItem><span className="mnu_frm" onMouseOver={j1} onMouseOut={k1} style={mnu1_tb}><FcAbout style={rmj}/>About</span></CListGroupItem></CCol> 
     <CCol  sm={3} md={3} lg={3}><CListGroupItem><span className="mnu_frm" onMouseOver={j2} onMouseOut={k2} style={mnu1_tb}><FcProcess style={rmj}/>Procedure</span></CListGroupItem></CCol> 
     <CCol sm={2} md={2} lg={2}><CListGroupItem><span className="mnu_frm" onMouseOver={j3} onMouseOut={k3} style={mnu1_tb}><MdContactSupport style={rmj}/>Contact</span></CListGroupItem></CCol> 
    <CCol  sm={2} md={2} lg={2}><CListGroupItem><span className="mnu_frm" onMouseOver={j4} onMouseOut={k4} style={mnu1_tb}><VscPreview style={rmj}/>Reviews</span></CListGroupItem></CCol> 
     <CCol  sm={2} md={2} lg={2}><CListGroupItem><span  className="mnu_frm" onMouseOver={j5} onMouseOut={k5} style={mnu1_tb}><FaUserAlt style={rmj}/><Link  style={{color:'black',textDecoration:'none'}} to="/login">Login</Link></span></CListGroupItem></CCol> 
       </CRow>
  </CContainer>
     </nav>
     )}
     {isMobile && (
       <>
       <CContainer fluid>
        <CRow>
       <CCol xs={12}>
         <CNav as="nav">
         <CNavLink href="#" style={header_c1}><FcAbout style={rmjt}/> About</CNavLink>
 <CNavLink href="#" style={header_c1}><FcProcess style={rmjt}/> Procedure</CNavLink>
 <CNavLink href="#" style={header_c1}><MdContactSupport style={rmjt}/> Contact</CNavLink>
 <CNavLink href="#" style={header_c1}><VscPreview style={rmjt}/> Reviews</CNavLink>
 <CNavLink href="#" style={header_c1}><Link  style={{color:'black',textDecoration:'none'}} to="/login"><FaUserAlt style={rmjt}/> Login</Link></CNavLink>
</CNav>
</CCol>
         </CRow>
         </CContainer>
       </>
     )}
      </div>
  );
}

export default Navbar